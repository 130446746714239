import React, { useEffect, useState, useRef } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from '@paljs/ui/Card';
import Col from '@paljs/ui/Col';
import Row from '@paljs/ui/Row';
import { Button } from '@paljs/ui/Button';
import { InputGroup } from '@paljs/ui/Input';
import { nodeDetails } from '../../actions/nodeDetailsAction';
import { Checkbox } from '@paljs/ui/Checkbox';
import EditDate from '../../components/date/editDate';
import { postEditUserData } from '../../actions/EditUserDatailsActions';
import LoadingSpinner from '../loader';
import { koot_falan } from '../../helpers/utils';
import { Toastr, ToastrRef, ToastrProps } from '@paljs/ui/Toastr';
import SelectGautra from './select-gautra';
import SelectVillage from './select-village';
import { display, textAlign } from '@mui/system';
import moment from 'moment';
import SelectName from './select-name';
import Modal from '../../Utilities/Modal';

const EditBasicDetails = ({ editName, node_id, onClose, updatePage, nodeDetailsToUpdate = null }) => {
  const [message, setMessage] = useState('');
  const [isLoading, setLoader] = useState(false);
  const [name, setName] = useState('');
  const [nickName, setNickName] = useState('');
  const [gender, setGender] = useState('male');
  const [gautraObj, setGautraObj] = useState();
  const [subCaste, setSubCaste] = useState('');
  const [pincode, setPincode] = useState('');
  const [village, setVillage] = useState('');
  const [alive, setAlive] = useState(true);
  const [married, setMarried] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState({});
  const [dateOfMarriage, setDateOfMarriage] = useState('');
  const [dateOfDeath, setDateOfDeath] = useState('');
  const [nodeId, setNodeId] = useState('');
  const [email, setEmail] = useState('');
  const [isMukhiya, setIsMukhiya] = useState(false);

  //these states used for update data from suggested nodes
  const [previousName, setPreviousName] = useState('');
  const [previousDateYear, setPreviousDateYear] = useState('');

  const [toastData, setToastData] = useState<ToastrProps>({
    position: 'topEnd',
    status: 'Success',
    duration: 2000,
    hasIcon: true,
    destroyByClick: true,
    preventDuplicates: false,
  });
  const toastrRef = useRef<ToastrRef>(null);

  useEffect(() => {
    detailsToBeEdited();
  }, []);

  const detailsToBeEdited = async () => {
    setMessage(`loading data of ${editName}`);
    setLoader(true);
    const response = await nodeDetails(node_id);
    if (response != undefined) {
      const toastTitle = 'Success';
      const toastMessage = 'Details Fetched Successfully';
      toastrRef.current?.add(toastMessage, toastTitle, { ...toastData });
      const data = response.basicProfile;
      console.log('basic details for edit', response);
      setName(data.name);
      setNickName(data.nickName);
      setGender(data.gender);
      setGautraObj({
        gautra: data.gautra,
        subcaste: data.subcaste,
      });
      setSubCaste(data.subcaste);
      setPincode(data?.currentAddress?.pinCode);
      setVillage(data.village);
      setAlive(data.alive);
      setMarried(data.married);
      setDateOfBirth(data.dateOfBirth);
      setDateOfDeath(data.dateOfDeath);
      console.log('date of death', dateOfDeath);
      setDateOfMarriage(data.dateOfMarriage);
      setNodeId(data.graph_node_id);
      setEmail(data.email);
      setIsMukhiya(data.is_mukhiya);
      setLoader(false);
    } else {
      const toastTitle = 'Error';
      const toastMessage = 'Error Occurred';
      toastrRef.current?.add(toastMessage, toastTitle, { ...toastData, status: 'Danger' });
      setLoader(false);
    }
  };

  const onMarriageChange = (value) => {
    setDateOfMarriage(value);
  };

  const onBirthChange = (value) => {
    setDateOfBirth(value);
  };

  const onDeathChange = (value) => {
    if (!alive) {
      setDateOfDeath(value);
    }
  };
  useEffect(() => {
    if (alive) {
      setDateOfDeath(''); // Reset dateOfDeath when marked alive
    }
  }, [alive]);

  const onSubmit = async () => {
    const kootFalan = koot_falan(name);
    setMessage(`Updating details of ${name}`);
    // setLoader(true);
    const data = {
      name,
      nickName,
      gender,
      gautra: gautraObj?.gautra,
      subCaste: gautraObj?.subcaste ?? '',
      pincode,
      village,
      alive,
      married,
      dateOfBirth,
      dateOfMarriage,
      dateOfDeath,
      email,
      isMukhiya,
      graph_node_id: nodeId,
      koot_falan: kootFalan,
    };

    const response = await postEditUserData(data);
    if (response) {
      const toastTitle = 'Success';
      const toastMessage = 'Details Fetched Successfully';
      toastrRef.current?.add(toastMessage, toastTitle, { ...toastData });
      onClose();
      updatePage();
    } else {
      const toastTitle = 'Error';
      const toastMessage = 'Error Occurred';
      toastrRef.current?.add(toastMessage, toastTitle, { ...toastData, status: 'Danger' });
    }
    console.log('after submitting the result is', response);
  };

  console.log('asdfghjklpoiuytrewq', nodeDetailsToUpdate);

  const editDetails = (
    <Modal toggle={onClose}>
      <Toastr ref={toastrRef} />
      <Card>
        {isLoading ? (
          <LoadingSpinner message={`${message}`} />
        ) : (
          <>
            <CardHeader>Edit Basic Details of {editName}</CardHeader>
            <CardBody>
              <Row style={{ display: 'flex', flexWrap: 'nowrap', flexDirection: 'column' }}>
                <div style={{ color: 'red', border: '1px solid ' }}>
                  Note :- नाम के आगे श्री/स्व/श्रीमती और अंत में जी, कूमार, कुमारी, देवी, गौत्र, समाज नाम इत्यादि न
                  लगाएँ, केवल मूल नाम लिखें{' '}
                </div>
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '10px',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <InputGroup style={{ width: '300px', padding: '5px', display: 'block' }} fullWidth>
                    <div>
                      Name:
                      {nodeDetailsToUpdate && name != nodeDetailsToUpdate?.name ? (
                        <span
                          style={{ color: 'blue', cursor: 'pointer' }}
                          onClick={() => {
                            setPreviousName(name);
                            setName(nodeDetailsToUpdate?.name);
                          }}
                        >
                          &nbsp;update name :{nodeDetailsToUpdate?.name}
                        </span>
                      ) : nodeDetailsToUpdate && previousName ? (
                        <span
                          style={{ color: 'blue', cursor: 'pointer' }}
                          onClick={() => {
                            setName(previousName);
                            setPreviousName('');
                          }}
                        >
                          &nbsp;previous name: {previousName}
                        </span>
                      ) : (
                        ''
                      )}
                    </div>
                    {/* <input
                                    value={name}
                                    placeholder="name"
                                    onChange={(event) => { setName((event.target.value).trim()) }}
                                /> */}
                    <SelectName setName={setName} gender={gender} name={name} />
                  </InputGroup>

                  {/* </Col>

                        <Col> */}

                  <InputGroup style={{ width: '300px', padding: '5px', display: 'block' }} fullWidth>
                    <div>Nick Name:</div>
                    <input
                      placeholder="Nick name"
                      value={nickName}
                      onChange={(event) => {
                        setNickName(event.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>

                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '10px',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  {/* <InputGroup>
                                <input
                                    value={gautra}
                                    placeholder="Gautra"
                                    onChange={(event) => { setGautra(event.target.value) }}
                                />
                            </InputGroup> */}
                  <div style={{ width: '300px', padding: '5px', display: 'block' }}>
                    Gautra:
                    <SelectGautra setGautraObj={setGautraObj} gautraObj={gautraObj} disabled={true} />
                  </div>
                  {/* </Col>

                        <Col> */}
                </Col>

                <Col style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap' }}>
                  <Checkbox
                    style={{ paddingLeft: '80px' }}
                    checked={isMukhiya}
                    onChange={(value) => {
                      setIsMukhiya(value);
                    }}
                  >
                    Mukhiya
                  </Checkbox>

                  <Checkbox
                    style={{ paddingLeft: '80px' }}
                    checked={alive}
                    onChange={(value) => {
                      setAlive(value);
                    }}
                  >
                    Alive
                  </Checkbox>

                  <Checkbox
                    style={{ paddingLeft: '80px' }}
                    checked={married}
                    onChange={(value) => {
                      setMarried(value);
                    }}
                  >
                    Married
                  </Checkbox>
                </Col>

                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '10px',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  {/* <InputGroup>
                                <input
                                    placeholder="village"
                                    value={village}
                                    onChange={(event) => { setVillage(event.target.value) }}
                                />
                            </InputGroup> */}
                  <div style={{ width: '300px', padding: '5px', display: 'block' }}>
                    Village:
                    <SelectVillage setVillage={setVillage} village={village} />
                  </div>

                  {/* </Col>

                        <Col> */}
                  <InputGroup style={{ width: '300px', padding: '5px', display: 'block' }} fullWidth>
                    <div>Pincode:</div>
                    <input
                      placeholder="Pincode"
                      value={pincode}
                      onChange={(event) => {
                        setPincode(event.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>

                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '10px',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <InputGroup style={{ display: 'block', width: '600px' }} fullWidth>
                    <div>Email:</div>
                    <input
                      value={email}
                      placeholder="Email"
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                    />
                  </InputGroup>
                </Col>
                <div style={{ paddingLeft: '60px' }}>Date of Birth :</div>
                {nodeDetailsToUpdate &&
                nodeDetailsToUpdate?.age &&
                dateOfBirth?.year != moment().year() - nodeDetailsToUpdate?.age ? (
                  <span
                    style={{ color: 'blue', cursor: 'pointer' }}
                    onClick={() => {
                      setPreviousDateYear(dateOfBirth?.year);
                      setDateOfBirth({ ...dateOfBirth, year: moment().year() - nodeDetailsToUpdate?.age });
                    }}
                  >
                    &nbsp;update birth year :{moment().year() - nodeDetailsToUpdate?.age}
                  </span>
                ) : nodeDetailsToUpdate && previousDateYear ? (
                  <span
                    style={{ color: 'blue', cursor: 'pointer' }}
                    onClick={() => {
                      setDateOfBirth({ ...dateOfBirth, year: previousDateYear });
                      setPreviousDateYear('');
                    }}
                  >
                    &nbsp;previous name: {previousName}
                  </span>
                ) : (
                  ''
                )}
                <Col
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    margin: '10px',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <div style={{ display: 'block' }}>
                    <EditDate defaultDate={dateOfBirth} onChange={onBirthChange} />
                  </div>
                </Col>
                {married && gender == 'male' ? (
                  <>
                    <div style={{ paddingLeft: '60px' }}>Date of Marriage :</div>

                    <Col
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        margin: '10px',
                        justifyContent: 'center',
                        flexWrap: 'wrap',
                      }}
                    >
                      <div style={{ display: 'block' }}>
                        <EditDate defaultDate={dateOfMarriage} onChange={onMarriageChange} />
                      </div>
                    </Col>
                  </>
                ) : (
                  ''
                )}

                <Col>
                  {!alive ? (
                    <>
                      <div>Date of Death:</div>
                      <EditDate defaultDate={dateOfDeath} onChange={onDeathChange} />
                    </>
                  ) : null}
                </Col>
              </Row>
            </CardBody>

            <CardFooter>
              <Row style={{ display: 'flex', justifyContent: 'center' }}>
                <Button
                  status="Success"
                  type="button"
                  shape="SemiRound"
                  style={{ margin: '10px', display: 'block' }}
                  onClick={onSubmit}
                >
                  submit
                </Button>

                <Button
                  status="Danger"
                  type="button"
                  shape="SemiRound"
                  style={{ margin: '10px', display: 'block' }}
                  onClick={onClose}
                >
                  cancel
                </Button>
              </Row>
            </CardFooter>
          </>
        )}
      </Card>
    </Modal>
  );
  return <>{editDetails}</>;
};
export default EditBasicDetails;
